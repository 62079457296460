/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewChild, NgZone, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { PopoverController, MenuController, AlertController } from '@ionic/angular';
import { Router} from '@angular/router';
import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { TranslationService } from 'src/app/services/translation.service';
// declare function openChat(username: string, userEmail: string, teamleaderGUID: string, documentGUID: string): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // @ViewChild('swiper', { static: true}) slider: swiper;
  // @ViewChild(‘swiper’) swiper: swiper;

  @Output() backEvent = new EventEmitter<string>();
  @Input() sideNavOpened: boolean;

  @HostListener('click', ['$event'])
  onClick(e) {
    if(!e.target.className.includes('header-close-icon') && !e.target.className.includes('header-book-icon')) {
      this.backEvent.next('directClosed');
    }
  }
  constructor(private router: Router,private _translationService: TranslationService , private alertController: AlertController, private ngZone: NgZone,  private _mainService: MainService,private popoverController: PopoverController, private _apiService: ApiService, public _commonService: CommonService, public menuCtrl: MenuController, private _authService: AuthService) { }
  slideOptsOne: {
    initialSlide: 0;
    slidesPerView: 1;
    autoplay: true;
  };

  ngOnInit() {

  }

  // slidesDidLoad(slides: swiper) {
  //   slides.activeIndex();
  // }

  async presentProfilePopover(event: any) {
    const popover = await this.popoverController.create({
      component: ProfilePopoverComponent,
      event,
      showBackdrop: false,
      cssClass: 'popPopOver',
      translucent: true
    });
    return await popover.present();
  }


  toggleMenu() {
    this.menuCtrl.toggle();
    if (window.innerWidth > 800) {
      //this._commonService.showSideMenu = !this._commonService.showSideMenu;
      this._commonService.showIconOnly = !this._commonService.showIconOnly;
      this._commonService.onToggle.emit('toggled');
      if (this._commonService.showSideMenu) {this._commonService.customActivityListSelectOptions.cssClass = 'custom-select-interface';}
      else {this._commonService.customActivityListSelectOptions.cssClass = '';}
    } else {
      this.menuCtrl.toggle();
    }

  }

  logout() {
    this._authService.logout();
  }
  goMessage(){
    this.router.navigate(['/notifications']);
  }
  goSetting(){
    this.router.navigate(['/maintenance']);
  }
  goProfile(){
    this.router.navigate(['/profile']);
  }

  goto(){


    if(this._commonService.isLoggedIn){
      this.router.navigate(['/dashboard']);
    }else{
      this.router.navigate(['/login']);
    }
  }

  hyperlinks(type) {
    let url;
    if(type === 'email') {
      url = 'mailto:'+this._commonService.helpDetail.Email;
    window.open(url, '_blank');
    } else if(type === 'phone') {
      url = 'tel:+'+this._commonService.helpDetail.Phone;
      window.open(url, '_blank');
    } else if(type !== 'email' && type !== 'phone')  {
      url = type;
      window.open(url, '_blank');
    }
  }

  // startChat(){
  //   const email = localStorage.userName ? localStorage.userName : 'Unknown';
  //   const firstName = localStorage.firstName ? localStorage.firstName : 'Unknown';
  //   const teamleaderGUID = localStorage.TeamLeaderGuid ?  localStorage.TeamLeaderGuid : 'Unknown';
  //   openChat(firstName, email, teamleaderGUID, undefined);
  // }

  menuOpened(){
    setTimeout(() => {

      const radio = document.querySelectorAll('ion-radio');
      if(radio) {

        for(let i=0;i<radio.length;i++) {
          const radioItem = radio[i];
          radioItem.shadowRoot.querySelector('.radio-icon').setAttribute('style', 'border: none;box-shadow: 0px 3px 0 0 #dfdfdf;background-color: #ffffff;curson:pointer');
          if(radioItem.className.includes('radio-checked')) {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0.8, 0.8, 0.8);');
          } else {
            radioItem.shadowRoot.querySelector('.radio-inner').setAttribute('style', 'transform: scale3d(0, 0, 0);');
          }

        }

      }

      // this.dismiss();
    }, 200);
  }

 async onDomainChange(event){
  const domain = this._commonService.domains.find(f => f.DomainId === event).Name;
      const alert = await this.alertController.create({
        header: this._commonService.getLocalTranslation(''),
        message: this._commonService.getLocalTranslation('label-domainChange'),
        buttons: [
          {
            text: this._commonService.getLocalTranslation('label-ok'),
            handler: () => {
              //  logout the user
              this._commonService.presentLoading();
              const params = {
                LocaleCode: ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043),
                DomainName:domain
              };

              this._authService.doDomainLogin(params, 'Login/Domain', localStorage.authenticationKey).subscribe(
                async (res) => {

                  const authenticationKey = localStorage.authenticationKey;
                  const menuItems = localStorage.menuItems;
                  const domains = localStorage.domains;
                  this._commonService.clearData();
                  localStorage.authenticationKey = authenticationKey;
                  localStorage.menuItems = menuItems;
                  localStorage.domains = domains;

                  localStorage.setItem('userId', res.UserId);
                  localStorage.setItem('userName', res.UserName);
                  localStorage.setItem('roleCode', res.RoleCode);
                  localStorage.setItem('domainLocales', JSON.stringify(res.Locales));
                  localStorage.setItem('menuItems', JSON.stringify(res.MenuItems));
                  localStorage.setItem('domainName', res.DomainName);
                  localStorage.setItem('domainId', res.DomainId);
                  localStorage.setItem('folderEntryAllowed', res.FolderEntryAllowed);
                  localStorage.setItem('MaxAllowedCompanyPerDomain', res.MaxAllowedCompanyPerDomain);
                  localStorage.setItem('helpImageChangeInterval', res.HelpImageChangeInterval);
                  localStorage.setItem('notificationsUnreadCount', res.NotificationsUnreadCount ? res.NotificationsUnreadCount : 0);
                  localStorage.setItem('Datecreated', res.body.Datecreated ? res.body.Datecreated : '');
                  localStorage.setItem('FirstLogin', res.body.FirstLogin ? res.body.FirstLogin : 'false');
                  localStorage.setItem('TeamLeaderGuid',res.body.TeamLeaderGuid);

                  this._commonService.notificationsUnreadCount = res.NotificationsUnreadCount ? res.NotificationsUnreadCount : 0;

                  if (res.Companies) {localStorage.setItem('companies', JSON.stringify(res.Companies));}
                  localStorage.setItem('userRoles', res.UserRoles ? JSON.stringify(res.UserRoles) : null);


                  this._commonService.domainLocales = res.Locales;
                  if (res.Locales.length === 1) {
                    this._commonService.locale = res.Locales[0].LocaleId;
                    localStorage.locale = res.Locales[0].LocaleId;
                  }

                  this._commonService.formatMenuItems();

                  this._translationService.getTranslation(localStorage.locale);

                  this._commonService.domainName = res.DomainName;
                  this._commonService.domain = {
                    DomainId: res.DomainId,
                    Name: res.DomainName
                  };



                  localStorage.domains = res.LinkedDomains ? JSON.stringify(res.LinkedDomains) : null;

                  if (this.router.url === '/dashboard') {
                    this._commonService.onDomainChange.emit('domainChanged');
                  }
                  else {
                    this.router.navigate(['/dashboard'], { replaceUrl: true });
                  }
                  try{
                    const moduleList = await this._apiService.getModules('', '', '', 1, 0);
                    localStorage.moduleList = JSON.stringify(moduleList.body);

                  }catch(error){
                      this._commonService.dismiss();
                      this._commonService.log(error, this._commonService.ERROR);
                  }
                  this._commonService.dismiss();

                },
                (err) => {
                  this._commonService.log(err, this._commonService.ERROR);
                  this._commonService.dismiss();
                }
              );
            }
          },
          {
            text: this._commonService.getLocalTranslation('label-cancel'),
            role: 'cancel',
            handler: () => {
            }
          }


        ]
      });
      await alert.present();

  }

  knowledgeBasePopOver(event){
    //this._commonService.presentAlert('', this._commonService.getLocalTranslation('label-knowledgeCenterComigSoon'))
    this.backEvent.next(event);
  }

}
